export const getOptionsId = (options: any) => {
  const editData = options?.data?.subscription?.edit
  const instantPaper = Array.isArray(editData)
    ? editData.filter(
        (item) =>
          item?.details?.productType === 'instantPaper' &&
          item?.enabled === true
      )
    : []
  return instantPaper.length > 0 ? (instantPaper[0]?.optionId ?? null) : null
}
