import React, { useEffect, useMemo, useState } from 'react'
import {
  useNotification,
  PrinterListOption,
  DisplayObserver,
  useAnalytics,
  parseAuxParams
} from '@monetization/hpaip-ui-shared-components'
import { SubscriptionStateEnum } from '@monetization/hpaip-notification-rules-react'
import dayjs from 'dayjs'
import {
  calculateContractDuration,
  findCoreOfferPlan,
  getInkOffers,
  getUserOffers
} from '../../api/services'
import { useBillingCycle } from '../../hooks/useBillingCycle'
import { useSubscriptionOptions } from '../../hooks/useSubscriptionOptions'
import { InkOffer, InkOffersRecord } from '../../types/instantink'
import {
  StyledPage,
  DropDownStyle,
  NotificationsContainer
} from './Page.styles'
import { PendingNotification } from '../PendingNotification/PendingNotification'
import { CommonProps } from 'src/types/mfeProps'
import NotificationContainer from './NotificationContainer'
import { useSubscription } from 'src/hooks/useSubscription'
import { MultipleSubscriptionPlan } from 'src/types/planInfo'
import { MainContent } from './MainContent'
import { getPendingSku } from 'src/utils/getPendingSku'
import { getPendingOffer } from '../../../src/utils/getPendingOffer'
import { getCurrentOffer } from 'src/utils/getCurrentOffer'
import PageDescription from '../PageDescription'
import useFetchMultipleSubscription from 'src/hooks/useFetchMultipleSubscription'

interface PageProps {
  commonProps?: CommonProps
  printerProps?: any
  setIndex?: React.Dispatch<React.SetStateAction<number>>
  index?: number
  subscriptionsData?: any
  graphQLproductData?: any
}

export interface NotificationData {
  title?: string
  description?: string
  notificationID?: string
  showCloseButton?: boolean
  notificationType?: any
}

export const Page: React.FC<PageProps> = ({
  commonProps,
  printerProps,
  setIndex,
  index,
  subscriptionsData,
  graphQLproductData
}) => {
  const { events } = useAnalytics()
  const { authProvider, stack, t } = commonProps
  const { setNotification } = useNotification()
  const [errorNotification, setErrorNotification] = useState<boolean>(false)
  const [revertPlan, setRevertPlan] = useState<boolean>(false)
  const [isPaperAdded, setIsPaperAdded] = useState(false)
  const subscriptionService = useSubscription({ authProvider, stack })
  const [getOptions, options] = useSubscriptionOptions({ authProvider, stack })
  const [getBillingCycles, billingCycles] = useBillingCycle({
    authProvider,
    stack
  })
  const [showDialog, setShowDialog] = useState<boolean>(false)
  const [isDowngrade, setIsDowngrade] = useState<boolean>(false)
  const [selectedOffer, setSelectedOffer] = useState<InkOffer>(null)
  const [chosenPendingOffer, setChosenPendingOffer] = useState<InkOffer>(null)
  const [errors, setErrors] = useState<any[]>([])
  const [printerData, setPrinterData] = useState<any>()
  const customerRequestedPlanChange = getPendingSku(printerProps?.printerData)
  const [pendingNotificationType, setPendingNotificationType] =
    useState<string>(null)
  const [showRevertloader, setShowRevertloader] = useState<boolean>(false)
  const [multipleSubscriptions, setMultipleSubscriptions] = useState<string[]>(
    []
  )
  const loading =
    options?.loading || printerData?.isLoading || billingCycles.loading

  const billingCycleEndDate =
    billingCycles?.data?.[0]?.items?.slice(-1)[0]?.endDate

  const nextBillingCycleStartDate =
    dayjs(billingCycleEndDate).format('MMM D, YYYY')

  const duration = useMemo(() => {
    return calculateContractDuration(graphQLproductData)
  }, [graphQLproductData])

  useEffect(() => {
    function fetchData() {
      try {
        const currentPrinterData = printerProps?.printerData ?? {}
        setPrinterData(currentPrinterData)
      } catch (error) {
        setPrinterData({
          error: true,
          isLoading: false,
          printer: null,
          called: true
        })
        console.error('Error retrieving subscriptions:', error)
      }
    }
    fetchData()
  }, [printerProps?.printerData])

  const { productData } = useFetchMultipleSubscription(
    subscriptionsData,
    graphQLproductData
  )

  useEffect(() => {
    if (productData && productData.length > 1) {
      setMultipleSubscriptions(productData)
    }
  }, [productData])
  const printerDetails = findCoreOfferPlan(
    graphQLproductData,
    printerData?.printer?.product?.value?.productSku
  )
  /**
   * Wait for the list of products from Mangento and list of available options from BizLogic
   * to be loaded, once both are available, it will filter the instant ink plans from the list of productSku
   * and then, it will use the list of options from BizLogic to filter those plan to only show the ones the customer
   * is supposed to see and which is the recommended one if any.
   **/
  const offers: InkOffersRecord = useMemo((): InkOffersRecord => {
    if (!options?.data) return null

    /* istanbul ignore next */
    const inkOffers: InkOffersRecord = getInkOffers(
      options?.data,
      printerData?.activePaperSubscription || isPaperAdded
    )

    let userOffers
    if (
      options?.data?.entities &&
      options?.data?.entities?.length > 0 &&
      options?.data?.entities[0]?.edit != null
    ) {
      userOffers = getUserOffers(inkOffers, options?.data?.entities[0]?.edit)
    } else {
      userOffers = inkOffers
    }
    return userOffers
  }, [options?.data])

  /**
   * After the customer changes the plan (downgrade or upgrade), the change will only take effect
   * at the end of the current billing cycle, so to inform the customer which plan is updating to
   * we need to check the `pendingSku` property in the instant ink entity, this will be the instant ink plan SKU
   * the customer chose to change to.
   **/

  const pendingOffer: InkOffer = useMemo(() => {
    return getPendingOffer(
      printerData,
      offers,
      customerRequestedPlanChange,
      chosenPendingOffer
    )
  }, [offers, printerData, chosenPendingOffer])

  const currentOffer: InkOffer = useMemo(() => {
    return getCurrentOffer(printerData, offers)
  }, [offers, printerData?.printer])

  const resetState = () => {
    setShowDialog(false)
    setSelectedOffer(null)
    setIsDowngrade(false)
    setRevertPlan(false)
    setIsPaperAdded(false)
  }

  const handleOnSubscribe = (offer: InkOffer, isDowngrade: boolean): void => {
    setIsDowngrade(isDowngrade)
    setSelectedOffer(offer)
    setShowDialog(true)
  }

  const handleChangePlanDialogClose = () => {
    resetState()
  }

  const getChangeInkPlanPayload = () => {
    return {
      optionId: selectedOffer?.optionId
    }
  }
  const handleChangePlanDialogConfirm = () => {
    setErrorNotification(false)
    subscriptionService
      .patch(printerData?.root?.subscriptionId, getChangeInkPlanPayload())
      .then(() => {
        setChosenPendingOffer(selectedOffer)
        resetState()
        getOptions(
          printerData?.root?.subscriptionId,
          printerData?.instantInk?.entityId
        )
      })
      .catch(() => {
        setErrorNotification(true)
        resetState()
      })
  }

  const getCurrentOfferPayload = () => {
    return {
      optionId: currentOffer?.optionId //+ '123'
    }
  }
  const handleRevertPlan = () => {
    setShowRevertloader(true)
    subscriptionService
      .patch(printerData?.root?.subscriptionId, getCurrentOfferPayload())
      .then(() => {
        setPendingNotificationType(null)
        setShowRevertloader(false)
        setChosenPendingOffer(currentOffer)
        resetState()
        setRevertPlan(true)
        getOptions(
          printerData?.root?.subscriptionId,
          printerData?.instantInk?.entityId
        )
      })
      .catch(() => {
        setShowRevertloader(false)
        setNotification(null)
        setPendingNotificationType('planRevertError')
        resetState()
      })
  }

  useEffect(() => {
    async function fetchData() {
      const dataSources: any[] = [options, printerData]
      setErrors(dataSources.filter((v) => !!v['error']))
    }
    if (printerData !== undefined) fetchData()
  }, [options?.error, printerData?.error, billingCycles?.error])

  useEffect(() => {
    const fetchData = () => {
      const subscriptionId = printerData?.root?.subscriptionId
      const entityId = printerData?.instantInk?.entityId

      if (!entityId) {
        console.error('Missing instant ink entity id, result:', entityId)
        return
      }

      const printerSku =
        printerData?.instantInk?.product?.value?.parentProductSku

      if (!printerSku) {
        console.error('Missing printer sku, result:', printerSku)
        return
      }

      getOptions(subscriptionId, entityId)
      // billing cycle data is not available when subscription state is "pending"
      if (
        printerData?.printer?.state.toLowerCase() !==
        SubscriptionStateEnum.PENDING
      ) {
        getBillingCycles(subscriptionId, printerData?.instantInk?.entityId)
      }
    }

    if (
      printerData?.printer !== undefined &&
      printerData?.instantInk !== undefined &&
      !printerData?.isLoading &&
      !options.called
    ) {
      fetchData()
    }
  }, [printerData])

  const printerState = useMemo(
    () => printerData?.printer?.state?.toLowerCase(),
    [printerData?.printer?.state]
  )

  const isPrinterCancelledORReturned = useMemo(() => {
    return printerState === SubscriptionStateEnum.CANCELED
  }, [printerState])

  useEffect(() => {
    if (
      pendingOffer &&
      pendingOffer?.sku !== currentOffer?.sku &&
      printerState !== SubscriptionStateEnum.CANCELED &&
      !printerData?.isCancellationTriggered &&
      printerState !== SubscriptionStateEnum.SUSPENDED
    ) {
      setNotification(null)
      setPendingNotificationType('planPending')
    }
  }, [pendingOffer, currentOffer, chosenPendingOffer])

  useEffect(() => {
    if (isPaperAdded) {
      setIsPaperAdded(true)
      getOptions(
        printerData?.root?.subscriptionId,
        printerData?.instantInk?.entityId
      )
    }
  }, [
    isPaperAdded,
    printerData?.root?.subscriptionId,
    printerData?.instantInk?.entityId
  ])

  const isError = Boolean(options.error)

  //code related to multiple subscriptions
  const handlePrinterSelect = (index: MultipleSubscriptionPlan) => {
    setIndex(index?.id)
    localStorage.setItem('index', index?.id.toString())
    alert('Data saved to localStorage!')
  }
  const booleanFlags = {
    loading,
    isError,
    isPrinterCancelledORReturned,
    showDialog,
    isDowngrade,
    isPaperAdded
  }
  const actions = {
    handleOnSubscribe,
    setIsPaperAdded,
    handleChangePlanDialogClose,
    handleChangePlanDialogConfirm
  }
  const inkOffers = {
    currentOffer,
    pendingOffer
  }

  return (
    <DisplayObserver
      eventProps={events.DisplayedUpdatePlan(
        parseAuxParams({
          subscriptionId: printerData?.root?.subscriptionId,
          subscriptionStartDate: printerData?.entityStartDate
        })
      )}
    >
      <StyledPage>
        {multipleSubscriptions.length > 0 && (
          <DropDownStyle>
            <PrinterListOption
              t={t}
              PrinterDetails={productData}
              defaultPrinter={index}
              onPrinterSelect={handlePrinterSelect}
              isOpen={true}
            ></PrinterListOption>
          </DropDownStyle>
        )}
        {!errors.length && !booleanFlags.isPrinterCancelledORReturned && (
          <PageDescription
            hasPaperAccess={
              printerData?.isRemovePaper || booleanFlags.isPaperAdded
            }
            t={t}
            subscriptionId={printerData?.root?.subscriptionId}
          />
        )}
        <NotificationsContainer>
          <PendingNotification
            t={t}
            pendingNotificationType={pendingNotificationType}
            pendingOffer={pendingOffer}
            nextBillingCycleStartDate={nextBillingCycleStartDate}
            showRevertloader={showRevertloader}
            handleRevertPlan={handleRevertPlan}
          />
          <NotificationContainer
            printerProps={printerProps}
            errorNotification={errorNotification}
            revertPlan={revertPlan}
            isPaperAdded={isPaperAdded}
          />
        </NotificationsContainer>
        <MainContent
          booleanFlags={booleanFlags}
          actions={actions}
          inkOffers={inkOffers}
          t={t}
          printerData={printerData}
          errors={errors}
          offers={offers}
          billingCycles={billingCycles}
          billingCycleEndDate={dayjs(billingCycleEndDate).format(
            'MMM DD, YYYY'
          )}
          nextBillingCycleStartDate={nextBillingCycleStartDate}
          selectedOffer={selectedOffer}
          commonProps={commonProps}
          printerDetails={printerDetails}
          duration={duration}
          newOptions={options}
        />
      </StyledPage>
    </DisplayObserver>
  )
}
