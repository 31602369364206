import React, { useEffect, useState, useMemo } from 'react'
import {
  useCommonNotification,
  useFlags
} from '@monetization/hpaip-notification-rules-react'
import { CommonProps } from 'src/types/mfeProps'
import { Page } from '../Page'
import { UpdatePlanAnalyticsProvider } from 'src/utils/UpdatePlanAnalyticsProvider'
import {
  HpxHeader,
  LoadingHandler
} from '@monetization/hpaip-ui-shared-components'
import { ErrorSection } from '../ErrorComponent/ErrorSection'
import { StyledHeader } from '../Page/Page.styles'
import { SubscriptionStateEnum } from '@monetization/hpaip-notification-rules-react'

interface Props {
  subscriptionId?: string
  commonProps?: CommonProps
  setIndex?: React.Dispatch<React.SetStateAction<number>>
  index?: number
  subscriptionsData?: any
}

const InitialComponent: React.FunctionComponent<Props> = ({
  subscriptionId,
  commonProps,
  setIndex,
  index,
  subscriptionsData
}) => {
  const { t } = commonProps
  const flags = useFlags()
  const [loading, setLoading] = useState<boolean>(true)
  const [error, setError] = useState<boolean>(false)
  const flagObject = {
    enableBuyerRemorseCancellation: flags?.enableBuyerRemorseCancellation,
    enableAddPaperPostEnrollment: flags?.enableAddPaperPostEnrollment,
    pfEnableNewReplacementFlow: flags?.pfEnableNewReplacementFlow
  }

  const data = useCommonNotification(
    'updatePlan',
    subscriptionId,
    flagObject,
    null,
    null
  )

  const graphQLproductData = data?.graphQLproductData

  useEffect(() => {
    if (data?.printerData?.root != null && graphQLproductData) {
      setLoading(false)
      setError(false)
    } else if (data?.printerData?.error) {
      setLoading(false)
      setError(true)
    }
  }, [data?.printerData?.error, data?.printerData?.root])

  const isSubscriptionInactive = useMemo(() => {
    const printerState = data?.printerData?.printer?.state
    if (!printerState) return true
    return printerState === SubscriptionStateEnum.CANCELED
  }, [data?.printerData?.printer?.state])

  return (
    <>
      <StyledHeader>
        {!isSubscriptionInactive && (
          <HpxHeader
            title={t('update-plan.header.title', 'Update HP All-In Plan')}
            showLogo={false}
          />
        )}
      </StyledHeader>
      {loading && <LoadingHandler loading={loading} error={false} />}
      {!loading && error && (
        <LoadingHandler
          loading={loading}
          error={error}
          customError={
            <ErrorSection
              t={t}
              title="Something went wrong"
              description="Your account information did not load."
              buttonText="Try again"
            />
          }
        />
      )}

      {!loading && !error && (
        <UpdatePlanAnalyticsProvider
          analytics={commonProps.analytics}
          subscriptionId={subscriptionId}
          entityStartDate={data?.printerData?.printer?.entityStartDate}
        >
          <Page
            printerProps={data}
            commonProps={commonProps}
            setIndex={setIndex}
            index={index}
            subscriptionsData={subscriptionsData}
            graphQLproductData={graphQLproductData}
          />
        </UpdatePlanAnalyticsProvider>
      )}
    </>
  )
}

export default InitialComponent
