import { ApolloProvider } from '@apollo/client'
import { ThemeProvider } from '@veneer/theme'
import React from 'react'
import { TranslatorFunctionType } from '../types/localization'
import { MfePropsType } from '../types/mfeProps'
import SubscriptionInfo from 'src/components/SubscriptionInfo/SubscriptionInfo'
import { getEnvironmentVariables, getStack } from '../helpers'
import useApolloClient from '../hooks/useApolloClient'
import { useFlags } from '@monetization/hpaip-notification-rules-react'
import { UpdatePlanRoot } from 'src/components/components/UpdatePlanRoot'

export const Root = ({
  t,
  authProvider,
  stack,
  analytics,
  localization,
  navigation
}: MfePropsType & { t: TranslatorFunctionType }) => {
  const envVariables = getEnvironmentVariables(getStack(stack))
  const graphqlClient = useApolloClient(
    authProvider,
    envVariables?.variables?.MAGENTO_URL
  )
  const flags = useFlags()

  return (
    <ApolloProvider client={graphqlClient}>
      <ThemeProvider density="high">
        {flags?.pfEnableNewUpdatePlanFlow ? ( // make this flag false for new flow
          // New code
          <UpdatePlanRoot />
        ) : (
          //Existing code
          <SubscriptionInfo
            commonProps={{
              authProvider,
              stack,
              t,
              analytics,
              localization,
              navigation
            }}
          />
        )}
      </ThemeProvider>
    </ApolloProvider>
  )
}
