import { InkOffersRecord } from '../../types/instantink'
import type { MultipleSubscriptionPlan } from '../../types/planInfo'
import {
  LEASE_PRICE_TYPE,
  LEASE_PRODUCT_TYPE,
  PAPER_PRODUCT_TYPE
} from 'src/helpers/constanst'
import moment from 'moment'

interface Product {
  hp_as_low_as_price: number
  hp_product_type_label: string
  hp_short_config_name: string
  sku: string
  hp_pages_per_month: number
  hp_rollover_pages: number
  hp_overage_block_size: number
  hp_overage_block_price: number
  hp_max_rollover_pages: number
  hp_trial_pages_per_month: null
  price: {
    regularPrice: {
      amount: { value: number }
    }
  }
  image: {
    url: string
  }
  thumbnail: {
    url: string
  }
}

interface ProductBundleOption {
  label: string
  product: Product
}

interface ProductBundleItem {
  title: string
  sku: string
  options: ProductBundleOption[]
}

interface ProductBundle {
  hp_max_days_to_return: number
  name: string
  sku: string
  items: ProductBundleItem[]
}

interface ProductResult {
  products: {
    items: ProductBundle[]
  }
}

export interface CoreOfferPlanInfo {
  sku: string
  title: string
  productimage: string
}

const calculateContractDuration = (data: ProductResult) => {
  const printerBundle = data?.[0]
  const inkBundle = {
    options: printerBundle?.items?.reduce((accumulator, item) => {
      const filtered = item?.options?.filter(
        (option) => option?.product?.hp_product_type_label === 'leaseReceivable'
      )
      return accumulator.concat(filtered)
    }, [])
  }

  const months = inkBundle?.options?.[0]?.product?.hp_contract_duration ?? 0
  const duration = moment.duration(months, 'months')
  const upgradeEligibilty = duration.years()
  return upgradeEligibilty
}

const findOptionsInkOffers = (
  optionsData: any,
  hasActivePaper: boolean
): InkOffersRecord => {
  const optionDetails = optionsData?.entities[0]?.edit

  // Using reduce to calculate the total cost from option API
  const offers = optionDetails?.reduce((acc, plan) => {
    if (!plan?.product?.value?.productSku) return acc
    return {
      ...acc,
      [plan.product.value.productSku]: {
        sku: plan?.product?.value?.productSku,
        pages: plan?.product?.value?.pagesPerMonth ?? 0,
        price: plan?.product?.value?.price?.cost ?? 0,
        rollover: plan?.product?.value?.maxRolloverPages ?? 0,
        overage: {
          size: plan?.product?.value?.overageBlockSize ?? 0,
          price: plan?.product?.value?.overageBlockPrice ?? 0
        },
        recommended: false,
        optionId: null,
        leasePrice:
          plan?.outcomes.length > 0
            ? plan?.outcomes?.filter(
                (outcome) =>
                  outcome.type === LEASE_PRICE_TYPE &&
                  outcome.value?.productType === LEASE_PRODUCT_TYPE
              )?.[0].value?.price?.cost || 0
            : 0,
        paperPrice:
          hasActivePaper && plan?.outcomes.length > 0
            ? plan?.outcomes?.filter(
                (outcome) =>
                  outcome.type === LEASE_PRICE_TYPE &&
                  outcome.value?.productType === PAPER_PRODUCT_TYPE
              )?.[0].value?.price?.cost || 0
            : 0
      }
    }
  }, {})

  return offers
}

const getInkOffers = (
  optionsData: any,
  hasActivePaper: boolean
): InkOffersRecord => {
  console.log('hasActivePaper', hasActivePaper)
  const offers = findOptionsInkOffers(optionsData, hasActivePaper)
  return offers
}

const findCoreOfferPlan = (
  results: ProductResult,
  sku: string
): CoreOfferPlanInfo | null => {
  const coreOffer = results?.[0]?.items
    .find((item) => item.title === 'Core Offer')
    ?.options.find((plan) => plan.product.sku === sku)

  if (!coreOffer) return null
  return {
    sku: coreOffer.product?.sku,
    title: coreOffer.product?.hp_short_config_name,
    productimage: coreOffer.product?.thumbnail.url
  }
}

const findCoreOfferPlanMultipleSubscription = (
  results: ProductResult,
  sku: string,
  subscriptionId: string,
  index: number
): MultipleSubscriptionPlan | null => {
  const printerBundle = results?.[0]
  const coreOfferBundle: ProductBundleItem = printerBundle?.items.find(
    (item) => item.title == 'Core Offer'
  )

  const coreOffer = coreOfferBundle?.options?.find(
    (plan) => plan.product.sku === sku
  )

  if (!coreOffer) return null
  return {
    id: index,
    label: coreOffer.product?.hp_short_config_name,
    planId: subscriptionId
  }
}

export {
  findCoreOfferPlan,
  findOptionsInkOffers,
  getInkOffers,
  findCoreOfferPlanMultipleSubscription,
  calculateContractDuration
}
