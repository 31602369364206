import { useState, useEffect, useCallback } from 'react'
import { findCoreOfferPlanMultipleSubscription } from '../../src/api/services'

export interface PrinterData {
  id: string
  label: string
  planId: string
}

interface UseFetchMultipleSkusResult {
  productData: any[]
}

const useFetchMultipleSubscription = (
  multipleSubscriptions: any,
  graphQLproductData: any
): UseFetchMultipleSkusResult => {
  const [productData, setProductData] = useState<any[]>([])

  const processSkus = useCallback(
    async (
      parentSkus: string[],
      productSkus: string[],
      multipleSubId: string[]
    ) => {
      for (let index = 0; index < parentSkus?.length; index++) {
        const coreOfferPlan = findCoreOfferPlanMultipleSubscription(
          graphQLproductData,
          productSkus[index],
          multipleSubId[index],
          index
        )
        setProductData((prevData) => [...prevData, coreOfferPlan])
      }
    },
    [graphQLproductData]
  )

  useEffect(() => {
    const fetchMultipleSku = async () => {
      if (multipleSubscriptions && multipleSubscriptions?.length > 1) {
        const { productSkus, parentSkus, multipleSubId } = getProductParentSku(
          multipleSubscriptions
        )
        await processSkus(parentSkus, productSkus, multipleSubId)
      }
    }

    fetchMultipleSku()
  }, [multipleSubscriptions, processSkus])

  const getProductParentSku = (subscriptionData) => {
    const productSkus: string[] = []
    const parentSkus: string[] = []
    const multipleSubId: string[] = []

    subscriptionData?.forEach((subscription: any) => {
      subscription?.entities?.forEach((entity: any) => {
        if (entity?.entityType === 'iot-printer') {
          productSkus.push(entity?.product?.value?.productSku)
          parentSkus.push(entity?.product?.value?.parentProductSku)
          multipleSubId.push(subscription?.subscriptionId)
        }
      })
    })

    return { productSkus, parentSkus, multipleSubId }
  }

  return { productData }
}

export default useFetchMultipleSubscription
